import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import Apollo from './config/Apollo'
import Routes from './config/Routes'
import Translation from './config/Translation'

ReactDOM.render(
  <Translation>
    <Apollo>
      <App>
        <Routes />
      </App>
    </Apollo>
  </Translation>,
  document.getElementById('root')
)

// serviceWorker.unregister()
