import React from 'react'
import { Steps } from 'antd'
import { dateFormatter, humanFormatter } from '../../../utilities/formatters'

const { Step } = Steps

const OrderSteps = ({ order }) => {
  const reprintRequest = order.reprintRequest
  const getStep = () => {
    if (order.status === 'created') {
      return 1
    }
    if (order.status === 'paid') {
      if (order.sentAt) {
        return 3
      }
      return 2
    }
    if (reprintRequest && reprintRequest.status === 'complete') return 5
    return 4
  }

  let reprintRequestStatus
  if (reprintRequest) {
    reprintRequestStatus = `Reprint ${humanFormatter(reprintRequest.status)}`
  }

  return (
    <Steps current={getStep()} style={{ paddingTop: 20 }}>
      <Step title="Began" subTitle={dateFormatter(order.createdAt)} description="Items added to cart" />
      <Step title="Submitted" subTitle={dateFormatter(order.purchasedAt)} description="Checked out" />
      <Step title="Sent to Lab" subTitle={dateFormatter(order.sentAt)} description="Lab received order" />
      <Step title="Fulfilled" description="Lab processed order" />
      {reprintRequest && <Step title={reprintRequestStatus} description="Reprint request exist" />}
    </Steps>
  )
}

export default OrderSteps
