import { ApolloProvider } from '@apollo/react-hooks'
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import React from 'react'
import { get } from '../utilities/storage'
import PubSub from 'pubsub-js'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    })
  }

  if (networkError) {
    if (networkError.statusCode === 403 || networkError.statusCode === 401) {
      PubSub.publish('SIGN_OUT')
    }
    console.log(`[Network error]: ${networkError}`)
  }
})

const authLink = setContext((request, { headers }) => {
  const jwt = get('jwt')
  let studioId = get('studioId')
  // Studios is a particular case where we don't want to use the studioId'
  // Or else once you select a studio you'll only ever get that studio back from the list
  if (request.operationName === 'StudiosListQuery') {
    studioId = null
  }
  if (jwt == null) return { headers: { ...headers } }

  return {
    headers: {
      ...headers,
      Authorization: jwt ? `Bearer ${jwt}` : '',
      Studio: `${studioId}`
    }
  }
})

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_PD_API
})

const link = errorLink.concat(authLink).concat(httpLink)
const cache = new InMemoryCache()

const client = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    mutate: { errorPolicy: 'ignore' }
  }
})

const Provider = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default Provider
