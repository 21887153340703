import React from 'react'
import PagedList from '../../shared/PagedList'
import refundListProps from '../../refunds/refundListProps'

const Refunds = ({ order }) => {
  const orderParams = {
    search: false,
    startParams: {
      order: 'created_at',
      dir: 'desc',
      purchasableId: order.id,
      purchasableType: 'Order'
    }
  }
  const props = { ...refundListProps, ...orderParams }

  return (
    <div style={{ padding: 0 }}>
      {order.refundable && <PagedList {...props} />}
      {!order.refundable && <p>This order does not contain a payout and is not refundable.</p>}
    </div>
  )
}

export default Refunds
