import React, { useState, useEffect } from 'react'

// GraphQL
import { TRANSFER_GIFT_CARD } from '../../graphql/mutations'
import { CUSTOMER_QUERY, GIFT_CARD_BY_NUM_QUERY } from '../../graphql/queries'

// Plugins
import { EditOutlined } from '@ant-design/icons'
import { Button, Descriptions, Tooltip, List, Typography, Form, Input, Col, Row, Divider, message, Modal } from 'antd'

// Components
import Drawer from '../shared/Drawer'
import { CreditLink } from '../shared/Links'
import DrawerLink from '../shared/DrawerLink'
import CustomerEditDrawer from './CustomerEditDrawer'

// Helpers / Hooks
import { useMutation } from '@apollo/react-hooks'
import useObjectQuery from '../../api/useObjectQuery'
import { hasPermission } from '../../utilities/permissions'
import { useGraphqlError, useErrors } from '../../utilities/hooks'
import { useApolloClient, useLazyQuery } from '@apollo/react-hooks'
import { dateFormatter, phoneFormatter, emailFormatter, mapCentsToCurrency } from '../../utilities/formatters'

// Constants
const canManageEntities = hasPermission('admin_manage_entities')

const { Item } = Descriptions
const { Title } = Typography

const CustomerDrawer = (props) => {
  const client = useApolloClient()
  const [form] = Form.useForm()

  const customerId = props.id
  const { object: customer } = useObjectQuery('customer', CUSTOMER_QUERY, customerId, { fetchPolicy: 'cache-and-network' })

  const [showTransferConfirm, setShowTransferConfirm] = useState(false)
  const [transferCredit, setTransferCredit] = useState(null)

  const giftCards = customer?.giftCards || []
  const sortedGiftCards = [...giftCards]?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
  const transferCreditExpired = transferCredit?.expiration && new Date(transferCredit.expiration) < new Date()

  const [transferGiftCard, { data: transferGiftCardData, loading: transferLoading, error: transferError }] = useMutation(TRANSFER_GIFT_CARD)
  useErrors(transferGiftCardData?.transferGiftCard?.errors)
  useGraphqlError(transferError)

  const [getGiftCardByNum, { data: giftCardData, loading: giftCardLoading, error: giftCardError }] = useLazyQuery(GIFT_CARD_BY_NUM_QUERY, {
    errorPolicy: 'all',
    onCompleted: (data) => setTransferCredit(data.giftCard)
  })
  useErrors(giftCardData?.giftCard?.errors)
  useGraphqlError(giftCardError)

  const handleGetGiftCard = async ({ creditNum }) => {
    if (creditNum) {
      getGiftCardByNum({
        variables: { num: creditNum.trim() }
      })

      if (!transferCredit && giftCardData?.giftCard?.num === creditNum) setTransferCredit(giftCardData.giftCard)
      setShowTransferConfirm(true)
    }
  }

  const handleGiftCardTransfer = async () => {
    if (!transferCredit || !customer) return null

    const id = transferCredit.id
    const customerId = customer.id
    const params = { variables: { input: { id, customerId } } }

    const response = await transferGiftCard(params)
    if (response?.data?.transferGiftCard?.errors?.length === 0) {
      message.success('Credit updated.')
      setShowTransferConfirm(false)

      form?.resetFields()
      client.query({
        query: CUSTOMER_QUERY,
        variables: { id: customerId },
        fetchPolicy: 'network-only'
      })
    } else {
      message.error('Error transferring credit.')
    }
  }

  const handleTransferCancel = () => {
    setShowTransferConfirm(false)
    setTransferCredit(null)

    form?.resetFields()
  }

  const onFinishFailed = (errorInfo) => console.log('Failed:', errorInfo)

  useEffect(() => {
    if (giftCardError) {
      form?.resetFields()
      console.log('getGiftCardByNum failed')
    }
  }, [giftCardError, form])

  if (customer == null) return null

  return (
    <>
      <Drawer {...props} width={600}>
        <Descriptions column={1} title={`Customer: ${customer.fullName}`} bordered>
          <Item label="First Name">{customer.firstName}</Item>
          <Item label="Last Name">{customer.lastName}</Item>
          <Item label="Phone">{phoneFormatter(customer.phone)}</Item>
          <Item label="Email">{emailFormatter(customer.email)}</Item>
          <Item label="Orders">{customer.ordersCount}</Item>
          <Item label="Created At">{dateFormatter(customer.createdAt)}</Item>
        </Descriptions>
        <DrawerLink drawerElement={CustomerEditDrawer} childProps={{ customer: customer }} clickable={canManageEntities} width={500}>
          <Tooltip title={canManageEntities ? null : 'You must request permission to be able to manage entities.'}>
            <div style={{ marginTop: 20, display: 'flex', justifyContent: 'end' }}>
              <Button disabled={!canManageEntities} type="primary" icon={<EditOutlined />}>
                Edit
              </Button>
            </div>
          </Tooltip>
        </DrawerLink>

        {sortedGiftCards && sortedGiftCards.length > 0 && (
          <>
            <Divider />
            <Title level={5} style={{ marginBottom: 20 }}>
              AdvancePay Credits
            </Title>
            <List bordered={true} style={{ maxHeight: '250px', overflowY: 'scroll', marginBottom: '30px' }}>
              {sortedGiftCards.map((giftCard, index) => (
                <List.Item key={index} style={{ flexDirection: 'column' }}>
                  <Row style={{ width: '100%' }}>
                    <Col span={12}>
                      <div level={5} style={{ fontWeight: 'bold' }}>
                        Num: <CreditLink credit={giftCard} />
                      </div>
                      <div>{`Studio: ${giftCard.studio.name}`}</div>
                      <div>{`Job: ${giftCard.job.name}`}</div>
                    </Col>
                    <Col span={12}>
                      <div>{`Beginning Balance: $${mapCentsToCurrency(giftCard, ['amountCents']).amountCents}`}</div>
                      <div>{`Remaining Balance: $${mapCentsToCurrency(giftCard, ['remainingAmountCents']).remainingAmountCents}`}</div>
                      <div>Purchased At: {dateFormatter(giftCard.createdAt, true)}</div>
                      <div>Expiration: {dateFormatter(giftCard.expiration, true)}</div>
                    </Col>
                  </Row>
                </List.Item>
              ))}
            </List>
          </>
        )}

        <Divider />

        <Title level={5} style={{ marginBottom: 20 }}>
          Transfer AdvancePay Credit to: {customer.fullName}
        </Title>
        <Form form={form} {...{ wrapperCol: { span: 16 } }} name="basic" onFinish={handleGetGiftCard} onFinishFailed={onFinishFailed}>
          <Form.Item name="creditNum" {...{ layout: 'horizontal' }}>
            <Row wrap={false} gutter={[12]}>
              <Col flex="auto">
                <Input placeholder="Enter credit Num to transfer" />
              </Col>
              <Col flex="none">
                <Button type="primary" htmlType="submit" loading={giftCardLoading}>
                  Transfer
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Drawer>

      <Modal
        title={`Transfer AdvancePay Credit`}
        visible={showTransferConfirm}
        zIndex={1100}
        centered
        disabled={transferCreditExpired}
        onOk={transferCreditExpired ? handleTransferCancel : handleGiftCardTransfer}
        confirmLoading={transferLoading}
        onCancel={handleTransferCancel}
      >
        {transferCredit && transferCreditExpired && (
          <div>
            <p style={{ fontSize: 18 }}>
              AdvancePay Credit Num: <strong>{transferCredit?.num} </strong>expired on <strong>{dateFormatter(transferCredit?.expiration, true)}</strong> and
              cannot be transferred.
            </p>

            <p>Expiration: {dateFormatter(transferCredit.expiration, true)}</p>
          </div>
        )}
        {transferCredit && !transferCreditExpired && (
          <>
            <p style={{ fontSize: 16 }}>
              Are you sure you want to transfer AdvancePay credit from <strong>{transferCredit?.customer?.fullName}</strong> to{' '}
              <strong>{customer.fullName}</strong>?
            </p>

            <Row gutter={[12]} style={{ width: '100%', border: '1px solid grey', padding: 15, borderRadius: 5 }}>
              <Col span={12}>
                <div>{`Num: ${transferCredit?.num}`}</div>
                <div>{`Studio: ${transferCredit.studio.name}`}</div>
                <div>{`Job: ${transferCredit.job.name}`}</div>
              </Col>
              <Col span={12}>
                <div>{`Beginning Balance: $${mapCentsToCurrency(transferCredit, ['amountCents']).amountCents}`}</div>
                <div>{`Remaining Balance: $${mapCentsToCurrency(transferCredit, ['remainingAmountCents']).remainingAmountCents}`}</div>
                <div>Purchased At: {dateFormatter(transferCredit.createdAt, true)}</div>
                <div>Expiration: {dateFormatter(transferCredit.expiration, true)}</div>
              </Col>
            </Row>
          </>
        )}
      </Modal>
    </>
  )
}

export default CustomerDrawer
