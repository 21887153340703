import React from 'react'
import DrawerLink from '../shared/DrawerLink'
import CreditDrawer from './CreditDrawer'

const CreditLink = ({ children, credit, clickable = true }) => {
  return (
    <DrawerLink drawerElement={CreditDrawer} childProps={{ id: credit.id }} clickable={clickable} width={500}>
      {children || credit.num}
    </DrawerLink>
  )
}

export default CreditLink
