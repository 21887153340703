import React from 'react'
import DrawerLink from '../shared/DrawerLink'
import CustomerDrawer from './CustomerDrawer'

const CustomerLink = ({ customer, clickable = true, children }) => {
  return (
    <DrawerLink drawerElement={CustomerDrawer} childProps={{ id: customer.id }} clickable={clickable} width={500}>
      {children || customer.fullName}
    </DrawerLink>
  )
}

export default CustomerLink
