import React from 'react'
import { Select } from 'antd'

const StaticSelectControl = ({ control, onChange }) => {
  const onInputChange = (value) => {
    let params = {}
    params[control.dataField] = value
    onChange(params)
  }

  return (
    <Select
      className="data-table-control"
      options={control.options}
      placeholder={control.placeholder}
      defaultValue={control.defaultValue}
      optionLabelProp="label"
      style={{ width: control.width || 200 }}
      onChange={onInputChange}
    />
  )
}

export default StaticSelectControl
