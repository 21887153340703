import React, { useEffect, useState } from 'react'

// GraphQL
import { SUPPORT_REQUEST_QUERY, ADDRESS_VALIDATION_QUERY } from '../../graphql/queries'
import { UPDATE_REPRINT_REQUEST, CREATE_SUPPORT_REQUEST_EVENT } from '../../graphql/mutations'

// Plugins
import PubSub from 'pubsub-js'
import { Form, Button, message, Select, Checkbox, Modal, Divider, Descriptions, Spin, Table, Input } from 'antd'

// Components
import Drawer from '../shared/Drawer'

// Helpers / Hooks
import { get } from '../../utilities/storage'
import useObjectQuery from '../../api/useObjectQuery'
import { humanFormatter } from '../../utilities/formatters'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { useGraphqlError, useErrors } from '../../utilities/hooks'

// Constants
const { Item } = Descriptions

const PERMITTED_ATTRIBUTES = ['recipient', 'line1', 'line2', 'city', 'state', 'zip', 'skipVerification']

const ApproveReprintDrawer = (props) => {
  const currentUser = get('user')
  const {
    request: { id: requstId },
    onClose
  } = props
  const [form] = Form.useForm()
  const [addressForm] = Form.useForm()

  const { object: request, loading: requestLoading } = useObjectQuery('supportRequest', SUPPORT_REQUEST_QUERY, requstId)

  const [addressValidation, { data: addressValidationData, loading: addressValidationLoading, error: addressValidationError }] =
    useLazyQuery(ADDRESS_VALIDATION_QUERY)
  useErrors(addressValidationData?.addressValidation?.errors)
  useGraphqlError(addressValidationError)

  const [createSupportRequestEvent] = useMutation(CREATE_SUPPORT_REQUEST_EVENT, {
    onError: () => {
      message.error('Error creating reprint approval comment')
    },
    onCompleted: async (data) => {
      if (!data) message.error('Error creating reprint approval comment')
    }
  })

  const [updateReprintRequest, { data, loading, error }] = useMutation(UPDATE_REPRINT_REQUEST, {
    onError: () => {
      message.error('Error updating reprint request')
    },
    onCompleted: async (data) => {
      if (data?.updateReprintRequest?.errors?.length === 0) {
        const timeStamp = data.updateReprintRequest.supportRequest.orderReprint.updatedAt

        await onApproveSuccess(timeStamp)

        message.success('Reprint request updated.')
        PubSub.publish('PAGED_LIST_REFRESH')

        onClose()
      } else {
        message.error(`Error updating reprint request: ${data?.updateReprintRequest?.errors[0].message}`)
      }
    }
  })
  useErrors(data?.updateReprintRequest?.errors)
  useGraphqlError(error)

  const [formValues, setFormValues] = useState({})
  const [shippingRatesList, setShippingRatesList] = useState([])
  const [showReprintItems, setShowReprintItems] = useState(false)
  const [showAddressForm, setShowAddressForm] = useState(false)
  const [showInvalidAddressModal, setShowInvalidAddressModal] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [tableData, setTableData] = useState([])
  const [newAddress, setNewAddress] = useState(null)
  const [invalidAddress, setInvalidAddress] = useState(null)

  const eligibleReprintItems = tableData.filter((item) => !item.rowHeader && !item.orderItemProductDigitalDownload)
  const isFullReprint = selectedRowKeys.length === eligibleReprintItems.length || !selectedRowKeys.length

  const order = request?.orderReprint?.order
  const orderAddress = order?.address
  const orderItems = order?.orderItems
  const orderReprintAddress = request?.orderReprint?.address
  const orderReprintPartial = request?.orderReprint?.partial
  const orderReprintReason = request?.orderReprint?.reason
  const orderReprintExtraInfo = request?.orderReprint?.extraInfo
  const shippingOptions = request?.orderReprint?.order?.shippingOptions
  const orderReprintProducts = request?.orderReprint?.orderReprintProducts
  const isApproveDisabled = orderReprintPartial && selectedRowKeys.length === 0

  const existingShippingAddress = newAddress || orderReprintAddress || orderAddress

  const onFinish = async (values, selectedRowKeys) => {
    const colorCorrection = values.colorCorrection ? true : false
    const orderReprintProductsAttributes = []

    if (!isFullReprint) {
      // Add selected items to orderReprintProductsAttributes
      selectedRowKeys.forEach((orderItemProductId) => {
        const orderReprintProduct = orderReprintProducts?.find((product) => product.orderItemProductId === orderItemProductId)

        orderReprintProductsAttributes.push({ orderItemProductId, ...(orderReprintProduct && { id: orderReprintProduct.id }) })
      })

      // Set items not selected for destroy
      orderReprintProducts.forEach((orderReprintProduct) => {
        const shouldDestroy = !selectedRowKeys.includes(orderReprintProduct.orderItemProductId)

        if (shouldDestroy)
          orderReprintProductsAttributes.push({ orderItemProductId: orderReprintProduct.orderItemProductId, id: orderReprintProduct.id, _destroy: true })
      })
    }

    const params = {
      variables: {
        input: {
          id: request.id,
          status: 'approved',
          shippingRateId: values.shippingRateId,
          colorCorrection: colorCorrection,
          ...(newAddress && { addressAttributes: { ...newAddress, id: orderReprintAddress?.id } }),
          ...(orderReprintProductsAttributes?.length > 0 && { orderReprintProductsAttributes })
        }
      }
    }

    await updateReprintRequest(params)
  }

  const onApproveSuccess = async (timeStamp) => {
    const reprintTypeApproved = isFullReprint ? 'Full' : 'Partial'
    const orderReprintProductsRequested = orderReprintProducts.map((product) => product.orderItemProductId).join(', ')
    const orderReprintProductsApproved = selectedRowKeys.join(', ')
    const isStudioRequestPartial = orderReprintProducts.length > 0
    const isApprovedRequestPartial = !isFullReprint

    const commentPrimary = `${currentUser.fullName} has approved a ${reprintTypeApproved} reprint on ( ${timeStamp} ). `
    const commentStudioRequest = isStudioRequestPartial
      ? `The studio requested the following items: [ ${orderReprintProductsRequested} ]. `
      : 'The studio requested a Full reprint. '
    const commentAdminApproved = isApprovedRequestPartial
      ? `The following items were approved: [ ${orderReprintProductsApproved} ]`
      : 'A Full reprint was approved'
    const comment = `${commentPrimary}\n${commentStudioRequest}\n${commentAdminApproved}`

    const input = { supportRequestId: request.id, comment, userId: currentUser.id }
    const params = { variables: { input: input } }

    const response = await createSupportRequestEvent(params)
    const errors = response.data?.createSupportRequestEvent?.errors

    if (errors && errors.length > 0) message.error('Error creating support request event')
  }

  const onFormValuesChange = (_, allValues) => {
    setFormValues(allValues)
  }

  const handleShowReprintItems = () => setShowReprintItems(true)
  const handleHideReprintItems = () => {
    setShowReprintItems(false)
    setStudioRequestedProducts(orderReprintProducts, eligibleReprintItems)
  }
  const handleCompleteReprintSelection = () => {
    setShowReprintItems(false)
  }

  const handleCancelAddressForm = () => {
    addressForm.resetFields()

    setShowAddressForm(false)
    setNewAddress(null)
  }
  const handleValidateAddressForm = async () => {
    addressForm
      .validateFields()
      .then((values) => {
        if (values.skipVerification) {
          addressForm.resetFields()

          setNewAddress(values)
          setShowAddressForm(false)
        } else {
          delete values.skipVerification

          addressValidation({ variables: { addressParams: values } }).then((response) => {
            const deliverability = response?.data?.addressValidation?.validationResult?.deliverability || null

            if (deliverability === 'deliverable') {
              const { components } = response?.data?.addressValidation?.validationResult
              const correctedAddress = {
                ...values,
                city: components.city || values.city,
                state: components.state || values.state,
                zip: components.zipCode ? `${components.zipCode}${components.zipCodePlus4 ? `-${components.zipCodePlus4}` : ''}` : values.zip
              }
              addressForm.resetFields()

              setNewAddress(correctedAddress)
              setShowAddressForm(false)
            } else {
              setInvalidAddress({ ...values, deliverability })
              setShowInvalidAddressModal(true)
            }
          })
        }
      })
      .catch((error) => {
        console.error('Validate Failed:', error)
      })
  }

  const handleInvalidAddressCancel = () => {
    setShowInvalidAddressModal(false)
    setInvalidAddress(null)
  }

  const handleInvalidAddressAccept = () => {
    addressForm.setFieldsValue({ skipVerification: true })
    addressForm.submit()
    setShowInvalidAddressModal(false)
  }

  const handleAddressChangeCancel = () => {
    setNewAddress(null)
    addressForm.resetFields()
  }

  const createTableData = (orderItems) => {
    const flattenData = []

    orderItems.forEach((orderItem) => {
      const isPackage = orderItem.priceSheetItem.priceSheetItemType === 'package'

      flattenData.push({
        id: orderItem.id,
        rowHeader: true,
        name: orderItem.name,
        description: orderItem.description,
        quantity: orderItem.quantity,
        isPackage: isPackage
      })
      orderItem.orderItemProducts.forEach((orderItemProduct) => {
        flattenData.push({
          id: orderItemProduct.id,
          rowHeader: false,
          orderItemProductDigitalDownload: orderItemProduct.digitalDownload,
          orderItemProductImageUrl: orderItemProduct.product.imageUrl,
          orderItemProductName: orderItemProduct.name,
          orderItemProductQuantity: orderItemProduct.quantity,
          orderItemProductNodes: orderItemProduct.orderItemProductNodes
        })
      })
    })

    setTableData(flattenData)
  }

  const createFormValues = (shippingOptions) => {
    const initialValues = { colorCorrection: false }
    const filteredShippingRates = shippingOptions?.filter((shippingRateItem) => !shippingRateItem?.bulk)

    if (filteredShippingRates?.length > 0) {
      setShippingRatesList(filteredShippingRates)
      initialValues.shippingRateId = filteredShippingRates[0].id
    }

    setFormValues(initialValues)
  }

  const setStudioRequestedProducts = (orderReprintProducts, eligibleReprintItems) => {
    if (!orderReprintProducts) return

    const newRowKeys = []

    orderReprintProducts
      // Filter out digital downloads from requested reprint products list
      .filter((product) => eligibleReprintItems?.some((item) => item.id === product.orderItemProductId))
      ?.forEach((product) => newRowKeys.push(product.orderItemProductId))

    setSelectedRowKeys(newRowKeys)
  }

  const onRow = (record) => {
    if (record.rowHeader) return { style: { background: '#f0f2f5', borderBottom: '2px #22bffd solid' } }
    if (record.orderItemProductDigitalDownload) return { style: { opacity: 0.5 } }
  }

  useEffect(() => {
    createFormValues(shippingOptions)
  }, [shippingOptions, shippingOptions?.length])

  useEffect(() => {
    if (orderItems && orderItems.length) createTableData(orderItems)
  }, [orderItems, orderItems?.length])

  useEffect(() => {
    if (orderReprintProducts && orderReprintProducts.length) setStudioRequestedProducts(orderReprintProducts, eligibleReprintItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, tableData.length])

  const columns = [
    {
      title: 'Products',
      width: 250,
      onCell: (record) => {
        if (record.rowHeader) {
          return {
            colSpan: 2
          }
        } else {
          return { colSpan: 1 }
        }
      },

      render: (_, row) => {
        if (row.rowHeader) {
          return (
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: '100%' }}>
              <h3 style={{ marginBottom: 3 }}>
                {row.name} <span style={{ fontSize: 12, fontWeight: 'normal' }}>{`(Quantity of ${row.quantity})`}</span>
              </h3>
              <p style={{ marginBottom: 0 }}>{row.description}</p>
            </div>
          )
        } else {
          const isRequested = orderReprintProducts?.some((product) => product.orderItemProductId === row.id)

          return (
            <div>
              {isRequested && <p style={{ color: 'green' }}>Reprint Requested</p>}
              <figure style={{ display: 'flex' }}>
                <img src={row.orderItemProductImageUrl} alt={row.orderItemProductName} style={{ width: 100, height: 'auto', objectFit: 'contain' }} />
                <figcaption style={{ paddingLeft: 20, display: 'flex', alignItems: 'center' }}>{row.orderItemProductName}</figcaption>
              </figure>
            </div>
          )
        }
      }
    },
    {
      title: 'Images',
      onCell: (record) => {
        if (record.rowHeader) {
          return {
            colSpan: 0,
            style: { display: 'none' }
          }
        } else {
          return { colSpan: 1 }
        }
      },
      render: (_, row) => {
        if (row.rowHeader) {
          return null
        } else {
          return (
            <>
              {row.orderItemProductNodes.map((node) => {
                if (!node.photoId) return null
                const description = node.layoutNode.description
                const finalImageUrl = node.previewImageUrls.small
                const fileName = node.photo.imageFileName
                const isRetouchApplied = node.photo.retouchedImageFileName

                return (
                  <figure style={{ display: 'flex', maxWidth: '100%' }} key={node.id}>
                    <img style={{ height: 'auto', width: 100, objectFit: 'contain' }} src={finalImageUrl} alt={fileName} />
                    <figcaption
                      style={{
                        paddingLeft: 20,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        maxWidth: `calc(100% - 120px)`
                      }}
                    >
                      <p style={{ wordWrap: 'break-word', maxWidth: '100%' }}>{fileName}</p>
                      <p style={{ wordWrap: 'break-word', maxWidth: '100%' }}>{`${description} Photo`}</p>
                      <p style={{ fontWeight: 'bold', fontSize: 12 }}>{isRetouchApplied && 'Basic Retouching Added'}</p>
                    </figcaption>
                  </figure>
                )
              })}
            </>
          )
        }
      }
    }
  ]

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
    getCheckboxProps: (record) => {
      if (record.rowHeader) return { disabled: true, style: { display: 'none' } }
      if (record.orderItemProductDigitalDownload) return { disabled: true }
    }
  }

  return (
    <Drawer {...props} width={600}>
      {requestLoading ? (
        <Spin spinning={true} style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} />
      ) : (
        <>
          <Descriptions column={1} title={`Approve request for order ${order?.num}`} bordered>
            <Item label="Reprint Reason">{humanFormatter(orderReprintReason)}</Item>
            <Item label="Reprint Details">{humanFormatter(orderReprintExtraInfo)}</Item>
            <Item label="Request type">{!orderReprintPartial ? 'Full' : 'Partial'}</Item>
            <Item label="Request count">{!orderReprintPartial ? 'All' : orderReprintProducts?.length}</Item>
            <Item label="Approve Count">{!orderReprintPartial && !selectedRowKeys?.length ? 'All' : selectedRowKeys?.length}</Item>
            {existingShippingAddress && (
              <Item
                label={`Shipping Address ${newAddress ? ' (Edited)' : ''}`}
                labelStyle={{ wordWrap: 'wrap', maxWidth: 80, color: newAddress ? 'red' : 'initial' }}
              >
                <dl style={{ padding: 15 }}>
                  {Object.keys(existingShippingAddress)
                    .filter((attr) => PERMITTED_ATTRIBUTES.includes(attr))
                    .map((key) => {
                      const isLine2 = key === 'line2'
                      const isSkipVerification = key === 'skipVerification'
                      const label = isSkipVerification ? 'Skip Verification' : key
                      const value = isSkipVerification ? (existingShippingAddress[key] ? 'True' : 'False') : existingShippingAddress[key]
                      const style = isSkipVerification ? { display: 'flex', gap: 10 } : null

                      if (isLine2 && !value) return null

                      return <DlElement key={key} label={label} value={value} style={style} />
                    })}
                </dl>

                {newAddress ? (
                  <Button style={{ display: 'block', marginLeft: 'auto' }} type="link" onClick={handleAddressChangeCancel}>
                    Cancel Change
                  </Button>
                ) : (
                  <Button style={{ display: 'block', marginLeft: 'auto', color: 'grey' }} onClick={() => setShowAddressForm(true)}>
                    Edit Address
                  </Button>
                )}
              </Item>
            )}
          </Descriptions>

          {formValues.shippingRateId ? (
            <Form form={form} initialValues={formValues} style={{ padding: '30px 0px 10px' }} name="reprint-approval-form" onValuesChange={onFormValuesChange}>
              <Form.Item label="Shipping Rate" name={['shippingRateId']}>
                <Select>
                  {shippingRatesList.map((rate, index) => {
                    return (
                      <Select.Option key={index} value={rate.id}>
                        {rate.description} ({rate.rate})
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
              {order && order.lab.key === 'whcc' && (
                <Form.Item name={['colorCorrection']} valuePropName="checked" label="Color Correction">
                  <Checkbox />
                </Form.Item>
              )}
            </Form>
          ) : (
            <Spin spinning={true} style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} />
          )}

          <Button
            style={{ marginLeft: 'auto', display: 'block', width: 190 }}
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={handleShowReprintItems}
          >
            View / Edit Reprint Items
          </Button>
          <Divider />
          <Button
            style={{ marginLeft: 'auto', display: 'block', width: 190 }}
            type="primary"
            htmlType="button"
            loading={loading}
            disabled={isApproveDisabled}
            onClick={() => onFinish(formValues, selectedRowKeys)}
          >
            Approve
          </Button>
        </>
      )}

      <Modal
        visible={showReprintItems}
        title={`Reprint Request`}
        zIndex={1100}
        centered
        onCancel={handleHideReprintItems}
        onOk={handleCompleteReprintSelection}
        width={650}
        height={800}
        style={{ overflow: 'hidden' }}
      >
        <h3>Reprint Items</h3>

        <Table
          className="reprint-drawer__table"
          rowKey="id"
          pagination={false}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          tableLayout="fixed"
          selectedRowKeys={selectedRowKeys}
          scroll={{ y: 500 }}
          rowClassName={(record) => (record.rowHeader ? 'reprint-drawer__table-row reprint-drawer__table-row--header' : 'reprint-drawer__table-row')}
          onRow={onRow}
        />
      </Modal>

      <Modal
        visible={showAddressForm}
        title={`Edit Shipping Address`}
        zIndex={1100}
        centered
        disabled={addressValidationLoading}
        onCancel={handleCancelAddressForm}
        onOk={() => addressForm.submit()}
        width={650}
        height={800}
        style={{ overflow: 'hidden' }}
      >
        <h3>Reprint Items</h3>
        {existingShippingAddress && (
          <Form
            disabled={addressValidationLoading}
            form={addressForm}
            initialValues={{ ...existingShippingAddress, skipVerification: false }}
            name="address_form"
            onFinish={handleValidateAddressForm}
          >
            <Form.Item label="Recipient" name="recipient" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Line 1" name="line1" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Line 2" name="line2">
              <Input />
            </Form.Item>
            <Form.Item label="City" name="city" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label="State" name="state" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Zip" name="zip" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Skip Verification" valuePropName="checked" name="skipVerification">
              <Checkbox />
            </Form.Item>
          </Form>
        )}
      </Modal>

      <Modal
        visible={showInvalidAddressModal}
        title={`Invalid Address`}
        zIndex={1100}
        centered
        onCancel={handleInvalidAddressCancel}
        onOk={handleInvalidAddressAccept}
        width={500}
        height={700}
        style={{ overflow: 'hidden' }}
      >
        <h3>We can't verify this address</h3>
        {invalidAddress && (
          <dl style={{ padding: 15, border: '1px solid #AAA', borderRadius: 5, backgroundColor: '#FFEB9C' }}>
            {Object.keys(invalidAddress)
              .filter((attr) => PERMITTED_ATTRIBUTES.includes(attr))
              .map((key) => {
                const isLine2 = key === 'line2'
                const isSkipVerification = key === 'skipVerification'
                const label = isSkipVerification ? 'Skip Verification' : key
                const value = invalidAddress[key]

                if (isSkipVerification || (isLine2 && !value)) return null

                return <DlElement key={key} label={label} value={value} />
              })}
          </dl>
        )}
        <p>
          The address could not be verified, which could result in delivery delays or lost packages. Would you like to use the address you’ve entered anyway?
        </p>
        <strong>Would you like to Skip Verification and use the address entered?</strong>
      </Modal>
    </Drawer>
  )
}

const DlElement = ({ label, value, style }) => (
  <span style={style ? style : { display: 'grid', gridTemplateColumns: '75px 1fr' }}>
    <dt style={{ textTransform: 'capitalize' }}>{label}:</dt>
    <dd style={{ marginBottom: 0 }}>{value}</dd>
  </span>
)

export default ApproveReprintDrawer
