import React from 'react'
import { Form, Input, Button, message } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import { useGraphqlError, useErrors } from '../../utilities/hooks'
import Drawer from '../shared/Drawer'
import PubSub from 'pubsub-js'
import { CREATE_USER } from '../../graphql/mutations'

const PHOTODAY_EMAIL = '@photoday.io'
const tailLayout = {
  wrapperCol: { offset: 6, span: 18 }
}
const inputLayout = {
  marginTop: 10
}

const CreateUserDrawer = (props) => {
  const { onClose } = props
  const [createUser, { data, loading, error }] = useMutation(CREATE_USER)
  useErrors(data?.createUser?.errors)
  useGraphqlError(error)

  const onFinish = async (values) => {
    const params = {
      variables: {
        input: {
          attributes: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: values.password
          }
        }
      }
    }
    const response = await createUser(params)
    if (response?.data?.createUser?.errors?.length === 0) {
      message.success('User Created.')
      PubSub.publish('PAGED_LIST_REFRESH')
      onClose()
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <Drawer {...props} width={700}>
      <h3>Create User</h3>
      <Form layout={'vertical'} style={{ padding: 2 }} name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item style={{ ...inputLayout }} label="First name" name="firstName" rules={[{ required: true, message: 'First name is required!' }]}>
          <Input />
        </Form.Item>
        <Form.Item style={{ ...inputLayout }} label="Last name" name="lastName" rules={[{ required: true, message: 'Last name is required!' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          style={{ ...inputLayout }}
          label={`Email (must be ${PHOTODAY_EMAIL})`}
          name="email"
          rules={[{ required: true, message: 'Email is required!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Password is required!' }]}>
          <Input.Password />
        </Form.Item>
        <Form.Item {...tailLayout} noStyle>
          <Button type="primary" htmlType="submit" loading={loading} style={{ marginTop: 10 }}>
            Create
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default CreateUserDrawer
