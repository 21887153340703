import React from 'react'
import { Tabs, Button, Tooltip } from 'antd'
import { HomeOutlined, ShoppingOutlined } from '@ant-design/icons'
import { useQueryParams, StringParam, withDefault } from 'use-query-params'
import { useParams } from 'react-router-dom'
import Info from '../orders/show/Info'
import Refunds from '../orders/show/Refunds'
import Payouts from '../orders/show/Payouts'
import useObjectQuery from '../../api/useObjectQuery'
import BaseLayout from '../layout/BaseLayout'
import Page from '../layout/Page'
import RefundCreateDrawer from '../refunds/RefundCreateDrawer'
import DrawerLink from '../shared/DrawerLink'
import { buildBreadCrumbs } from '../../utilities/breadcrumbs'
import { ORDER_QUERY } from '../../graphql/queries'
import { hasPermission } from '../../utilities/permissions'
import OrderActionsDropdown from '../orders/OrderActionsDropdown'
const { TabPane } = Tabs

const Order = () => {
  const { num } = useParams()
  const { object: order } = useObjectQuery('order', ORDER_QUERY, num)
  const [queryParams, setQueryParams] = useQueryParams({
    section: withDefault(StringParam, 'info')
  })
  const { section } = queryParams

  if (order == null)
    return (
      <BaseLayout section="orders" breadcrumb={breadcrumb}>
        <Page loading />
      </BaseLayout>
    )

  const canManageRefunds = hasPermission('admin_manage_refunds')
  let extraContent = <OrderActionsDropdown order={order} />
  if (section === 'refund' && order.refundable)
    extraContent = (
      <DrawerLink drawerElement={RefundCreateDrawer} childProps={{ order: order, payoutId: order.payoutId }} clickable={canManageRefunds} width={500}>
        {canManageRefunds && <Button type="primary">Create</Button>}
        {!canManageRefunds && (
          <Tooltip title="You must request permission to be able to manage refunds.">
            <Button type="primary" disabled={!canManageRefunds}>
              Create Refund
            </Button>
          </Tooltip>
        )}
      </DrawerLink>
    )
  return (
    <BaseLayout section="orders" breadcrumb={breadcrumb}>
      <Page>
        <Tabs
          defaultActiveKey={section}
          tabBarExtraContent={extraContent}
          onChange={(value) => {
            setQueryParams({ section: value })
          }}
        >
          <TabPane tab="Info" key="info">
            <Info order={order} />
          </TabPane>
          <TabPane tab="Refunds" key="refund">
            <Refunds order={order} />
          </TabPane>
          <TabPane tab="Payouts" key="payout">
            <Payouts order={order} />
          </TabPane>
        </Tabs>
      </Page>
    </BaseLayout>
  )
}

const breadcrumb = buildBreadCrumbs([
  {
    href: '/',
    icon: <HomeOutlined />
  },
  {
    icon: <ShoppingOutlined />,
    name: 'Payments'
  },
  {
    href: '/orders',
    name: 'Orders'
  }
])

export default Order
