import React from 'react'
import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import gravatar from 'gravatar'
import UserDrawer from './UserDrawer'
import DrawerLink from '../shared/DrawerLink'

const UserLink = ({ clickable = true, user, children }) => {
  if (user == null) return <></>
  const profileUrl = gravatar.url(user.email, { s: '100', d: 'mp' })
  const name = <span>{user.fullName || `${user.firstName} ${user.lastName}`}</span>
  const avatar = <Avatar src={profileUrl} size={36} style={{ marginRight: 10 }} icon={<UserOutlined />} />
  return (
    <DrawerLink drawerElement={UserDrawer} childProps={{ id: user.id }} clickable={clickable}>
      {children || (
        <>
          {avatar}
          {name}
        </>
      )}
    </DrawerLink>
  )
}

export default UserLink
