import React from 'react'
import { Drawer } from 'antd'

const DrawerComponent = (props) => {
  const { title, visible, onClose, width, children } = props

  return (
    <Drawer className="drawer-form" onClose={onClose} title={title} visible={visible} width={width || 720} bodyStyle={{ paddingBottom: 80 }} zIndex={1050}>
      {children}
    </Drawer>
  )
}

export default DrawerComponent
