import React from 'react'
import { Form, Input, Select, Checkbox, Tooltip } from 'antd'
import { inputNumberValidator } from '../../utilities/validators'
import { humanFormatter } from '../../utilities/formatters'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { get } from '../../utilities/storage'

const reasons = ['duplicate', 'fraudulent', 'requested_by_customer', 'other_reason']
const regarding = ['shipping', 'taxes', 'fees', 'products', 'offers', 'advance_pay', 'whole_purchase', 'other']

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
}

export const refundRequestFromParams = (params, purchasable) => {
  let values = params
  values.refund.amountCents = parseInt(values.refund.amountCents * 100.0)
  values.reversals[0].amountCents = parseInt(values.reversals[0].amountCents * 100.0)
  values.reversals[0]['targetType'] = 'Studio'
  values.reversals[0]['targetId'] = purchasable.studio.id
  if (purchasable.lab) {
    values.reversals[1].amountCents = parseInt(values.reversals[1].amountCents * 100.0)
    values.reversals[1]['targetType'] = 'Lab'
    values.reversals[1]['targetId'] = purchasable.lab.id
  } else {
    values.reversals.pop()
  }
  values['refundAttributes'] = values.refund
  values['reversalsAttributes'] = values.reversals
  delete values['refund']
  delete values['reversals']
  return { variables: { input: { attributes: values } } }
}

const RefundForm = (props) => {
  const { payoutId, maximumsFormatted, form, children, customer, studio, lab, onFinish, onFinishFailed, refund } = props
  const user = get('user')
  let initialValues = {
    ...{
      userId: user.id,
      payoutId: payoutId,
      refund: { autoProcess: true, amountCents: maximumsFormatted.customer },
      reversals: [
        {
          autoProcess: true,
          amountCents: maximumsFormatted.studio
        },
        {
          autoProcess: true,
          amountCents: maximumsFormatted.lab
        }
      ]
    },
    ...(refund || {})
  }
  if (refund) {
    initialValues.refund.amountCents = initialValues.refund.amountCents / 100.0
    if (initialValues.reversals.length > 0) initialValues.reversals[0].amountCents = initialValues.reversals[0].amountCents / 100.0
    if (initialValues.reversals.length > 1) initialValues.reversals[1].amountCents = initialValues.reversals[1].amountCents / 100.0
  }

  return (
    <Form {...layout} form={form} style={{ padding: 20 }} name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed} initialValues={initialValues}>
      <Form.Item name="userId" style={{ display: 'none' }}>
        <Input />
      </Form.Item>
      <Form.Item name="payoutId" style={{ display: 'none' }}>
        <Input />
      </Form.Item>
      <Form.Item label="Regarding" name="regarding" rules={[{ required: true, message: 'Regarding is required. Select other if applicable.' }]}>
        <Select>
          {regarding.map((regard) => {
            return (
              <Select.Option key={regard} value={regard}>
                {humanFormatter(regard)}
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item label="Reason" name="reason" rules={[{ required: true, message: 'Reason is required. Select other if applicable.' }]}>
        <Select>
          {reasons.map((reason) => {
            return (
              <Select.Option key={reason} value={reason}>
                {humanFormatter(reason)}
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item label="Notes" name="notes">
        <Input.TextArea />
      </Form.Item>
      {!refund && (
        <>
          <Form.Item
            label={
              <span>
                Customer Amount&nbsp;
                <Tooltip title={`${customer.fullName} (${customer.email})`}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            name={['refund', 'amountCents']}
            style={{ marginBottom: 4 }}
            rules={[
              { required: true, message: 'Customer amount is required even if 0' },
              {
                min: 0,
                max: maximumsFormatted.customer,
                validator: inputNumberValidator,
                message: `Customer amount must be between 0 and ${maximumsFormatted.customer}`
              }
            ]}
          >
            <Input prefix="$" suffix="USD" />
          </Form.Item>
          <Form.Item {...tailLayout} name={['refund', 'autoProcess']} valuePropName="checked">
            <Checkbox>
              <Tooltip title="If auto process is selected, the server will continue to try to process until funds are available.">
                Auto Process&nbsp;
                <QuestionCircleOutlined />
              </Tooltip>
            </Checkbox>
          </Form.Item>
          <Form.Item
            label={
              <span>
                Studio Reversal&nbsp;
                <Tooltip title={studio.name}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            name={['reversals', 0, 'amountCents']}
            style={{ marginBottom: 4 }}
            rules={[
              { required: true, message: 'Studio amount is required even if 0' },
              {
                min: 0,
                max: maximumsFormatted.studio,
                validator: inputNumberValidator,
                message: `Studio amount must be between 0 and ${maximumsFormatted.studio}`
              }
            ]}
          >
            <Input prefix="$" suffix="USD" />
          </Form.Item>
          <Form.Item {...tailLayout} name={['reversals', 0, 'autoProcess']} valuePropName="checked">
            <Checkbox>
              <Tooltip title="If auto process is selected, the server will continue to try to process until funds are available.">
                Auto Process&nbsp;
                <QuestionCircleOutlined />
              </Tooltip>
            </Checkbox>
          </Form.Item>
          {lab && (
            <Form.Item
              label={
                <span>
                  Lab Reversal&nbsp;
                  <Tooltip title={lab?.name ?? 'N/A'}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name={['reversals', 1, 'amountCents']}
              style={{ marginBottom: 4 }}
              rules={[
                { required: true, message: 'Lab amount is required even if 0' },
                {
                  min: 0,
                  max: maximumsFormatted.lab,
                  validator: inputNumberValidator,
                  message: `Lab amount must be between 0 and ${maximumsFormatted.lab}`
                }
              ]}
            >
              <Input prefix="$" suffix="USD" />
            </Form.Item>
          )}
          <Form.Item {...tailLayout} name={['reversals', 1, 'autoProcess']} valuePropName="checked">
            <Checkbox>
              <Tooltip title="If auto process is selected, the server will continue to try to process until funds are available.">
                Auto Process&nbsp;
                <QuestionCircleOutlined />
              </Tooltip>
            </Checkbox>
          </Form.Item>
        </>
      )}
      {children}
    </Form>
  )
}

export default RefundForm
