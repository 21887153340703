import React from 'react'
import { Button, Tooltip } from 'antd'
import DrawerLink from '../shared/DrawerLink'
import CreateUserDrawer from './CreateUserDrawer'
import { get } from '../../utilities/storage'

const CreateUserButton = () => {
  const isSuperAdmin = get('user')?.isSuperAdmin

  return (
    <DrawerLink drawerElement={CreateUserDrawer} clickable={isSuperAdmin} width={500} childProps={{ isSuperAdmin }}>
      <div style={{ float: 'right' }}>
        <Tooltip title={isSuperAdmin ? null : 'You must request access to be able to create users'}>
          <Button disabled={!isSuperAdmin} type="primary">
            Create User
          </Button>
        </Tooltip>
      </div>
    </DrawerLink>
  )
}

export default CreateUserButton
