import React, { useCallback } from 'react'

// Plugins
import PubSub from 'pubsub-js'
import { Dropdown, Menu, message, Tooltip, Popconfirm } from 'antd'

// GraphQL
import { DELETE_ORDER, DESTROY_CUSTOMER } from '../../graphql/mutations'
import { CUSTOMER_SMS_CODE_QUERY } from '../../graphql/queries'

// Components
import CustomerLink from './CustomerLink'
import DrawerLink from '../shared/DrawerLink'
import { DownOutlined } from '@ant-design/icons'
import CustomerEditDrawer from './CustomerEditDrawer'

// Helpers / Hooks
import { useMutation } from '@apollo/react-hooks'
import { hasPermission } from '../../utilities/permissions'
import { useErrors, useGraphqlError } from '../../utilities/hooks'
import useImperativeQuery from '../../utilities/useImperativeQuery'

const CustomerActionsDropdown = ({ customer }) => {
  const customerCartId = customer.cartId
  const canManageEntities = hasPermission('admin_manage_entities')

  const smsQuery = useImperativeQuery(CUSTOMER_SMS_CODE_QUERY, { variables: { id: customer.id } })

  const [deleteOrder, { data: dataDeleteOrder, error: errorDeleteOrder, loading: loadingDeleteOrder }] = useMutation(DELETE_ORDER, {
    onError: (error) => {
      message.error(`Error while deleting cart: ${error?.message}`)
    },
    onCompleted: (data) => {
      if (data.deleteOrder?.errors?.length === 0) message.success('Cart Deleted.')
    }
  })
  useGraphqlError(errorDeleteOrder)
  useErrors(dataDeleteOrder?.deleteOrder?.errors)

  const [destroyCustomer, { error: errorDestroyCustomer, loading: loadingDestroyCustomer }] = useMutation(DESTROY_CUSTOMER, {
    onError: (error) => {
      message.error(`Error while deleting account: ${error?.message}`)
    },
    onCompleted: (data) => {
      if (data.destroyCustomer?.errors?.length === 0) {
        message.success('Account Deleted.')
        PubSub.publish('PAGED_LIST_REFRESH')
      } else {
        message.error(`Error while deleting account: ${data.destroyCustomer?.errors[0]?.message}`)
      }
    }
  })
  useGraphqlError(errorDestroyCustomer)

  const getSmsCode = useCallback(async () => {
    const result = await smsQuery()
    const code = result.data.customer.smsCode
    if (code) {
      message.success(`SMS code for ${customer.phone} is ${result.data.customer.smsCode}`, 15)
    } else {
      message.warn('No recent code found. Have the customer try to login again')
    }
  }, [customer, smsQuery])

  const handleDestroyCustomer = () => {
    destroyCustomer({ variables: { input: { id: customer.id } } })
  }

  const handleDeleteCart = () => {
    deleteOrder({ variables: { input: { id: customerCartId } } })
  }

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <CustomerLink customer={customer}>View</CustomerLink>
      </Menu.Item>
      <Menu.Item key="1">
        <Tooltip title={canManageEntities ? null : 'You must request permission to be able to manage entities.'}>
          <DrawerLink drawerElement={CustomerEditDrawer} childProps={{ customer: customer }} clickable={canManageEntities} width={500}>
            <span style={canManageEntities ? null : { color: 'gray' }}>Edit Customer</span>
          </DrawerLink>
        </Tooltip>
      </Menu.Item>
      <Menu.Item key="2">
        <Tooltip title={canManageEntities ? null : 'You must request permission to be able to manage entities.'}>
          <span onClick={getSmsCode} style={canManageEntities ? { cursor: 'pointer' } : { color: 'gray' }}>
            Get SMS Code
          </span>
        </Tooltip>
      </Menu.Item>
      {customerCartId && (
        <Menu.Item key="3">
          <Tooltip title={canManageEntities ? null : 'You must request permission to be able to manage entities.'}>
            <Popconfirm
              title={`Are you sure you want to clear this customer's cart`}
              disabled={!canManageEntities}
              loading={loadingDeleteOrder}
              onConfirm={handleDeleteCart}
            >
              <span style={canManageEntities ? null : { color: 'gray' }}>Clear Cart</span>
            </Popconfirm>
          </Tooltip>
        </Menu.Item>
      )}
      <Menu.Item key="4">
        <Tooltip title={canManageEntities ? null : 'You must request permission to be able to manage entities.'}>
          <Popconfirm
            title={`Are you sure you want to clear this customer's cart`}
            disabled={!canManageEntities}
            loading={loadingDestroyCustomer}
            onConfirm={handleDestroyCustomer}
          >
            <span style={canManageEntities ? { color: 'red' } : { color: 'gray' }}>Delete Account</span>
          </Popconfirm>
        </Tooltip>
      </Menu.Item>
    </Menu>
  )
  return (
    <>
      <Dropdown overlay={menu} trigger={['click']}>
        <span className="ant-dropdown-link">
          Actions &nbsp;
          <DownOutlined />
        </span>
      </Dropdown>
    </>
  )
}

export default CustomerActionsDropdown
