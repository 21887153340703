import React, { useCallback } from 'react'

// GraphQL
import { UPDATE_REPRINT_REQUEST, UPDATE_SMS_CAMPAIGN_REQUEST } from '../../graphql/mutations'

// Plugins
import PubSub from 'pubsub-js'
import { DownOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Tooltip, message } from 'antd'

// Components
import DenyDrawer from './DenyDrawer'
import DrawerLink from '../shared/DrawerLink'
import ApproveReprintDrawer from './ApproveReprintDrawer'

// Helpers / Hooks
import { useMutation } from '@apollo/react-hooks'
import { hasPermission } from '../../utilities/permissions'
import { useGraphqlError, useErrors } from '../../utilities/hooks'

const RequestActionsDropdown = ({ request, requestType }) => {
  const hasAccess = hasPermission('admin_support_requests')
  const [updateSmsCampaignRequest, { data: updateSmsData, loading: updateSmsLoading, error: updateSmsCampaignError }] = useMutation(
    UPDATE_SMS_CAMPAIGN_REQUEST,
    { errorPolicy: 'all' }
  )
  useErrors(updateSmsData?.updateSmsCampaignRequest?.errors)
  useGraphqlError(updateSmsCampaignError)

  const [updateReprintRequest, { data: updateReprintData, loading: updateReprintLoading, error: updateReprintError }] = useMutation(UPDATE_REPRINT_REQUEST, {
    errorPolicy: 'all'
  })
  useErrors(updateReprintData?.updateReprintRequest?.errors)
  useGraphqlError(updateReprintError)

  const loading = updateSmsLoading || updateReprintLoading

  const handleUpdateRequest = useCallback(
    async (action) => {
      const params = {
        variables: {
          input: {
            id: request.id,
            status: null
          }
        }
      }

      // Set the params based on the action
      if (action === 'approve') {
        params.variables.input.status = 'approved'
      }
      if (action === 'approveManual') {
        params.variables.input.status = 'approved_manual'
      }
      if (action === 'complete') {
        params.variables.input.status = 'complete'
        params.variables.input.comment = 'Manual Complete'
      }
      if (requestType === 'reprint') {
        params.variables.input.shippingRateId = request.orderReprint?.shippingRateId ? request.orderReprint?.shippingRateId : null
      }

      // Submit the request
      if (requestType === 'smsCampaign') {
        const response = await updateSmsCampaignRequest(params, {})
        const errors = response.data?.updateSmsCampaignRequest?.errors || response?.errors

        if (errors && errors.length > 0) return message.error(errors[0].message)
      } else if (requestType === 'reprint') {
        const response = await updateReprintRequest(params, {})
        const errors = response.data?.updateReprintRequests?.errors || response?.errors
        if (errors && errors.length > 0) return message.error(errors[0].message)
      } else {
        return message.error('Invalid request type.')
      }

      message.success('Support Request updated.')
      PubSub.publish('PAGED_LIST_REFRESH')
    },
    [request, updateReprintRequest, updateSmsCampaignRequest, requestType]
  )

  const menu = (
    <Menu>
      {request.status !== 'approved' && request.status !== 'complete' && request.status !== 'in_progress' && (
        <Menu.Item key="1">
          {hasAccess && request.requestType !== 'reprint' && (
            <div className="link" onClick={() => handleUpdateRequest('approve')} style={{ cursor: 'pointer' }}>
              Approve
            </div>
          )}
          {hasAccess && request.requestType === 'reprint' && (
            <DrawerLink drawerElement={ApproveReprintDrawer} childProps={{ request: request }} clickable={hasAccess} width={500}>
              {hasAccess && <span>Approve</span>}
            </DrawerLink>
          )}
          {!hasAccess && (
            <Tooltip title="You must request permission to be able to manage support entities.">
              <span style={{ color: 'gray' }}>Approve</span>
            </Tooltip>
          )}
        </Menu.Item>
      )}
      {request.status !== 'approved' &&
        request.status !== 'approved_manual' &&
        request.status !== 'complete' &&
        request.status !== 'in_progress' &&
        request.requestType === 'reprint' && (
          <Menu.Item key="2">
            {hasAccess && (
              <div className="link" onClick={() => handleUpdateRequest('approveManual')} style={{ cursor: 'pointer' }}>
                Approve - Manual
              </div>
            )}
            {!hasAccess && (
              <Tooltip title="You must request permission to be able to manage support entities.">
                <span style={{ color: 'gray' }}>Approve - Manual</span>
              </Tooltip>
            )}
          </Menu.Item>
        )}
      {request.status !== 'complete' && request.status !== 'denied' && request.requestType === 'reprint' && (
        <Menu.Item key="3">
          {hasAccess && (
            <div className="link" onClick={() => handleUpdateRequest('complete')} style={{ cursor: 'pointer' }}>
              Complete
            </div>
          )}
          {!hasAccess && (
            <Tooltip title="You must request permission to be able to manage support entities.">
              <span style={{ color: 'gray' }}>Complete</span>
            </Tooltip>
          )}
        </Menu.Item>
      )}
      {request.status !== 'denied' && request.status !== 'complete' && (
        <Menu.Item key="4">
          <DrawerLink drawerElement={DenyDrawer} childProps={{ request: request }} clickable={hasAccess} width={500}>
            {hasAccess && <span>Deny</span>}
            {!hasAccess && (
              <Tooltip title="You must request permission to be able to manage entities.">
                <span style={{ color: 'gray' }}>Deny</span>
              </Tooltip>
            )}
          </DrawerLink>
        </Menu.Item>
      )}
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']} disabled={loading}>
      <span className="ant-dropdown-link">
        Actions &nbsp;
        <DownOutlined />
      </span>
    </Dropdown>
  )
}

export default RequestActionsDropdown
