import React from 'react'
import { Form, Input, Button, message, Select } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import { useGraphqlError, useErrors } from '../../utilities/hooks'
import Drawer from '../shared/Drawer'
import { UPDATE_ORDER } from '../../graphql/mutations'

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}
const tailLayout = {
  wrapperCol: { offset: 6, span: 18 }
}

const OrderEditDrawer = (props) => {
  const { order, onClose } = props
  const [updateOrder, { data, loading, error }] = useMutation(UPDATE_ORDER)
  useErrors(data?.updateOrder?.errors)
  useGraphqlError(error)

  const onFinish = async (values) => {
    const params = { variables: { input: { id: order.id, attributes: values } } }
    const response = await updateOrder(params)
    if (response?.data?.updateOrder?.errors?.length === 0) {
      message.success('Order updated.')
      onClose()
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const initialValues = {
    phone: order.phone,
    email: order.email,
    addressAttributes: {
      recipient: order.address.recipient,
      line1: order.address.line1,
      line2: order.address.line2,
      city: order.address.city,
      state: order.address.state,
      zip: order.address.zip
    },
    shippingRateId: order.shippingRateId
  }

  return (
    <Drawer {...props} width={600}>
      <h3>Order Edit: {order.num}</h3>
      <Form {...layout} style={{ padding: 20 }} name="basic" initialValues={initialValues} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Order email required' }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Phone" name="phone" rules={[{ required: true, message: 'Order phone required' }]}>
          <Input />
        </Form.Item>

        <h4>Address</h4>
        <Form.Item label="Recipient" name={['addressAttributes', 'recipient']}>
          <Input />
        </Form.Item>
        <Form.Item label="Line 1" name={['addressAttributes', 'line1']}>
          <Input />
        </Form.Item>
        <Form.Item label="Line 2" name={['addressAttributes', 'line2']}>
          <Input />
        </Form.Item>
        <Form.Item label="City" name={['addressAttributes', 'city']}>
          <Input />
        </Form.Item>
        <Form.Item label="State" name={['addressAttributes', 'state']}>
          <Input />
        </Form.Item>
        <Form.Item label="Zip" name={['addressAttributes', 'zip']}>
          <Input />
        </Form.Item>

        <h4>Shipping</h4>
        <Form.Item label="Shipping Rate" name={'shippingRateId'}>
          <Select>
            {order.shippingOptions.map((rate, index) => {
              return (
                <Select.Option key={index} value={rate.id}>
                  {rate.description}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default OrderEditDrawer
