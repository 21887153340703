import React from 'react'
import { Breadcrumb } from 'antd'

/**
 * Builds breadcrumbs based on an object
 * Example:
 * [{
 *  href: "/",
 *  icon: <HomeOutlined />
 *  name: null
 * },{
 *  href: "/studios",
 *  name: "Studios"
 * }]
 * @param {object[]]} details - The bread crumb details
 * @returns {Breadcrumb} - An antd breadcrumb object
 */
export const buildBreadCrumbs = (details) => {
  const items = details.map((object) => {
    return (
      <Breadcrumb.Item href={object.href} key={`${object.href}-${object.icon}-${object.name}`}>
        {object.icon && object.icon}
        {object.name && <span>{object.name}</span>}
      </Breadcrumb.Item>
    )
  })
  return <Breadcrumb>{items}</Breadcrumb>
}
