import React from 'react'
import { HomeOutlined, BuildOutlined } from '@ant-design/icons'
import BaseLayout from '../layout/BaseLayout'
import Page from '../layout/Page'
import PagedList from '../shared/PagedList'
import jobListProps from '../jobs/jobListProps'
import { buildBreadCrumbs } from '../../utilities/breadcrumbs'

const Jobs = () => {
  return (
    <BaseLayout section="jobs" breadcrumb={breadcrumb}>
      <Page>
        <PagedList {...jobListProps} />
      </Page>
    </BaseLayout>
  )
}

const breadcrumb = buildBreadCrumbs([
  {
    href: '/',
    icon: <HomeOutlined />
  },
  {
    icon: <BuildOutlined />,
    name: 'Store'
  },
  {
    href: '/jobs',
    name: 'Jobs'
  }
])

export default Jobs
