import React from 'react'

// GraphQL
import { JOB_QUERY } from '../../graphql/queries'
import { UPDATE_JOB } from '../../graphql/mutations'

// Plugins
import moment from 'moment'
import { EditOutlined } from '@ant-design/icons'
import { Descriptions, Tooltip, Button, Form, DatePicker, Row, Col, message, Divider, Typography } from 'antd'

// Components
import Drawer from '../shared/Drawer'
import JobEditDrawer from './JobEditDrawer'
import { StudioLink } from '../shared/Links'
import DrawerLink from '../shared/DrawerLink'

// Helpers / Hooks
import Copy from '../shared/Copy'
import { useMutation } from '@apollo/react-hooks'
import useObjectQuery from '../../api/useObjectQuery'
import { hasPermission } from '../../utilities/permissions'
import { useGraphqlError, useErrors } from '../../utilities/hooks'
import { dateFormatter, currencyFormatter } from '../../utilities/formatters'

// Constants
const { Title } = Typography
const { Item } = Descriptions
const canManageEntities = hasPermission('admin_manage_entities')

const JobDrawer = (props) => {
  const { id } = props
  const [form] = Form.useForm()
  const { object: job } = useObjectQuery('job', JOB_QUERY, id)
  const bulkShipOrder = job?.bulkShipOrder

  const initialValues = {
    bulkShipExpiration: bulkShipOrder?.expiresAt ? moment(bulkShipOrder.expiresAt, 'YYYY-MM-DD') : moment()
  }

  const [updateJob, { data, loading, error }] = useMutation(UPDATE_JOB, {
    onError: (error) => {
      console.error('onError', error)
      form.resetFields()
    },
    onCompleted: (data) => {
      if (data?.updateJob?.errors?.length === 0) {
        message.success('Job updated.')
      } else {
        form.resetFields()
        if (!data) message.error('Error updating job.')
      }
    }
  })
  useErrors(data?.updateJob?.errors)
  useGraphqlError(error)

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo)
  }

  const onFinish = async (values) => {
    const dateString = moment(values.bulkShipExpiration).toISOString()

    const params = { variables: { input: { id: job.id, attributes: { bulkShipOrder: { expiresAt: dateString } } } } }
    await updateJob(params)
  }

  if (job)
    return (
      <Drawer {...props} width={600}>
        <Descriptions column={1} title={`Job: ${job.name}`} bordered>
          <Item label="ID">
            <Copy text={job.id}>{job.id}</Copy>
          </Item>
          <Item label="Studio">
            <StudioLink studio={job.studio} />
          </Item>
          <Item label="Views">{job.purchasesSummary.orderCount}</Item>
          <Item label="Orders">{job.purchasesSummary.orderCount}</Item>
          <Item label="Credits Sold">{job.purchasesSummary.giftCardCount}</Item>
          <Item label="Order Sales">{currencyFormatter(job.purchasesSummary.orderSales)}</Item>
          <Item label="Credit Sales">{currencyFormatter(job.purchasesSummary.creditSales)}</Item>
          <Item label="Used Credits">{currencyFormatter(job.purchasesSummary.usedCredits)}</Item>
          <Item label="Unused Credits">{currencyFormatter(job.purchasesSummary.unusedCredits)}</Item>
          <Item label="Total Sales">{currencyFormatter(job.purchasesSummary.totalSales)}</Item>
          <Item label="Studio Payouts">{currencyFormatter(job.purchasesSummary.studioPayouts)}</Item>
          <Item label="Average Order Value">{currencyFormatter(job.purchasesSummary.avgOrderValue)}</Item>
          <Item label="Created At">{dateFormatter(job.createdAt, true)}</Item>
          <Item label="Expires At">{dateFormatter(job.expiresAt, true)}</Item>
          <Item label="Last Published At">{dateFormatter(job.lastPublishedAt, true)}</Item>
          <Item label="Created At">{dateFormatter(job.createdAt)}</Item>
          <Item label="Auto SMS Enabled">{job.autoSmsEnabled ? 'True' : 'False'}</Item>
          <Item label="Auto SMS Start Date">{dateFormatter(job.openedAt, true)}</Item>
          {job.yearbookSelectionEnabled && (
            <>
              <Item label="Yearbook Selection Enabled">True</Item>
              <Item label="Yearbook Selection Deadline">{dateFormatter(job.yearbookSelectionDeadline, true)}</Item>
            </>
          )}
        </Descriptions>
        <Tooltip title={canManageEntities ? null : 'You must request permission to be able to manage entities.'}>
          <DrawerLink drawerElement={JobEditDrawer} childProps={{ job }} clickable={canManageEntities} width={500}>
            <div style={{ paddingTop: 20, display: 'flex', justifyContent: 'end' }}>
              <Tooltip title={canManageEntities ? null : 'You must request access to be able to manage entities'}>
                <Button disabled={!canManageEntities} type="primary" icon={<EditOutlined />}>
                  Edit
                </Button>
              </Tooltip>
            </div>
          </DrawerLink>
        </Tooltip>
        {bulkShipOrder && (
          <>
            <Divider />
            <div style={{ padding: 20, background: '#F1F1F1', borderRadius: 5 }}>
              <Title level={5} style={{ marginTop: 20, marginBottom: 20 }}>
                Bulk Ship Expiration
              </Title>
              <p>
                Expiration may be a max of 30 days past <strong>Last Published At</strong>.
              </p>

              <Form form={form} name="basic" initialValues={initialValues} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <Form.Item label="Expiration" style={{ marginBottom: 0 }}>
                  <Row wrap={false} gutter={[12]}>
                    <Col>
                      <Form.Item name="bulkShipExpiration" style={{ marginBottom: 0 }}>
                        <DatePicker
                          allowClear={false}
                          disabledDate={(current) => {
                            const customDate = moment().format('YYYY-MM-DD')
                            const isPastDate = current && current < moment(customDate, 'YYYY-MM-DD')
                            const is30DaysPastLastPublishedAt = current && current > moment(job.lastPublishedAt).add(30, 'days')

                            return isPastDate || is30DaysPastLastPublishedAt
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Tooltip title={canManageEntities ? null : 'You must request access to be able to update entities.'}>
                        <Button type="primary" htmlType="submit" loading={loading} disabled={!canManageEntities}>
                          Save
                        </Button>
                      </Tooltip>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </div>
          </>
        )}
      </Drawer>
    )

  return null
}

export default JobDrawer
