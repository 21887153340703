import React from 'react'
import { Button, Descriptions, Tooltip } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import useObjectQuery from '../../api/useObjectQuery'
import { dateFormatter, checkFormatter, phoneFormatter, emailFormatter } from '../../utilities/formatters'
import Copy from '../shared/Copy'
import Drawer from '../shared/Drawer'
import DrawerLink from '../shared/DrawerLink'
import UserEditDrawer from './UserEditDrawer'
import { StudioLink } from '../shared/Links'
import { USER_QUERY } from '../../graphql/queries'
import { hasPermission } from '../../utilities/permissions'

const { Item } = Descriptions

const UserDrawer = (props) => {
  const { id } = props
  const { object: user } = useObjectQuery('user', USER_QUERY, id)
  if (user == null) return <></>
  const canManageEntities = hasPermission('admin_manage_entities')
  return (
    <Drawer {...props} width={480}>
      <Descriptions column={1} size="small" title={`User: ${user.fullName}`} bordered>
        <Item label="Name">
          <Copy text={user.fullName}>{user.fullName}</Copy>
        </Item>
        <Item label="Studio">{user.studio && <StudioLink studio={user.studio} />}</Item>
        <Item label="Phone">{phoneFormatter(user.phone)}</Item>
        <Item label="Email">{emailFormatter(user.email)}</Item>
        <Item label="Admin">{checkFormatter(user.isPhotoday)}</Item>
        <Item label="E-mail Verified">{checkFormatter(user.verified)}</Item>
        <Item label="Created At">{dateFormatter(user.createdAt)}</Item>
      </Descriptions>
      <DrawerLink drawerElement={UserEditDrawer} childProps={{ user: user }} clickable={canManageEntities} width={500}>
        <div style={{ paddingTop: 20, float: 'right' }}>
          <Tooltip title={canManageEntities ? null : 'You must request access to be able to update entities.'}>
            <Button disabled={!canManageEntities} type="primary" icon={<EditOutlined />}>
              Edit
            </Button>
          </Tooltip>
        </div>
      </DrawerLink>
    </Drawer>
  )
}

export default UserDrawer
