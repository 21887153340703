import { Card, Col, Row } from 'antd'
import React from 'react'
import notFound from '../../assets/images/not_found.png'
import Page from '../layout/Page'

const NotFound = () => {
  return (
    <Page requiresAuth={false}>
      <Row type="flex" justify="center" style={{ paddingTop: 80 }}>
        <Col span={8}>
          <Card span={8} style={{ textAlign: 'center' }}>
            <img src={notFound} alt="Not found" width="100%" />
          </Card>
        </Col>
      </Row>
    </Page>
  )
}

export default NotFound
