import React, { useState } from 'react'
import { Layout } from 'antd'
import { useWindowSize } from '@react-hook/window-size/throttled'
import photodayLogo from '../../assets/images/photoday.png'
import photodayLogoSmall from '../../assets/images/photoday_small.png'
import Header from './header/Header'
import Navigation from './Navigation'
import { get, set } from '../../utilities/storage'

const { Footer, Sider } = Layout

const BaseLayout = ({ children, section, breadcrumb }) => {
  const [width] = useWindowSize()
  const [refresh, setRefresh] = useState(false)
  const collapsed = get('collapsed') || width < 600
  const logo = collapsed ? photodayLogoSmall : photodayLogo
  return (
    <Layout style={{ minHeight: '100vh' }} hasSider>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={() => {
          set('collapsed', !collapsed)
          setRefresh(!refresh)
        }}
      >
        <div className="logo" style={{ width: '100%' }}>
          <img src={logo} alt="PhotoDay Support Admin" style={{ width: '50%' }} />
        </div>
        <Navigation section={section} selectSection={!collapsed} />
      </Sider>
      <Layout>
        <Header breadcrumb={breadcrumb} />
        {children}
        <Footer />
      </Layout>
    </Layout>
  )
}

export default BaseLayout
