import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import StudioDropdown from './StudioDropdown'
import { UserLink } from '../../shared/Links'
import { LogoutOutlined } from '@ant-design/icons'
import { clear, get } from '../../../utilities/storage'
import PubSub from 'pubsub-js'

const { SubMenu } = Menu
const { Header } = Layout

const HeaderComponent = ({ breadcrumb }) => {
  const navigate = useNavigate()
  const signOut = () => {
    clear()
    navigate('/sign-in')
  }
  PubSub.subscribe('SIGN_OUT', signOut)
  const studioId = get('studioId')
  const user = get('user') || {}

  return (
    <Header className="header" style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <div style={{ display: 'flex', alignItems: 'flex-start', padding: 20, width: '100%' }}>{breadcrumb}</div>
      <StudioDropdown displayReportingCode studioId={studioId} />
      <Menu key="user" mode="horizontal" className="current-user">
        <SubMenu key="sign-out" title={<UserLink user={user} />}>
          <Menu.Item key="SignOut" onClick={signOut} className="sign-out">
            <LogoutOutlined />
            &nbsp; Sign out
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Header>
  )
}

export default HeaderComponent
