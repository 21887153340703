import React from 'react'
import { Menu, Dropdown, Tooltip } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import DrawerLink from '../shared/DrawerLink'
import RefundEditDrawer from './RefundEditDrawer'
import RefundLink from './RefundLink'
import { hasPermission } from '../../utilities/permissions'

const RefundActionsDropdown = ({ refund }) => {
  const canManageRefunds = hasPermission('admin_manage_refunds')
  const menu = (
    <Menu>
      <Menu.Item key="0">
        <RefundLink refund={refund}>View</RefundLink>
      </Menu.Item>
      <Menu.Item key="1">
        <DrawerLink drawerElement={RefundEditDrawer} childProps={{ refund: refund }} clickable={canManageRefunds} width={500}>
          {canManageRefunds && <span>Edit Refund</span>}
          {!canManageRefunds && (
            <Tooltip title="You must request permission to be able to manage refunds.">
              <span style={{ color: 'gray' }}>Edit Refund</span>
            </Tooltip>
          )}
        </DrawerLink>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <span className="ant-dropdown-link">
        Actions &nbsp;
        <DownOutlined />
      </span>
    </Dropdown>
  )
}

export default RefundActionsDropdown
