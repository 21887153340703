import React from 'react'
import { DatePicker } from 'antd'

const RangePickerControl = ({ control, onChange }) => {
  const onPickerChange = (dates) => {
    let params = {}
    const dateFrom = dates ? dates[0] : null
    const dateTo = dates ? dates[1] : null

    params[control.dateFrom] = dateFrom ? dateFrom.format('YYYY-MM-DD') : null
    params[control.dateTo] = dateTo ? dateTo.format('YYYY-MM-DD') : null

    onChange(params)
  }

  return <DatePicker.RangePicker allowEmpty={[true, true]} onChange={onPickerChange} className="data-table-control" />
}

export default RangePickerControl
