import React, { useCallback } from 'react'

// GraphQL
import { UPDATE_REPRINT_REQUEST, UPDATE_SMS_CAMPAIGN_REQUEST } from '../../graphql/mutations'

// Plugins
import PubSub from 'pubsub-js'
import { DownOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Tooltip, message } from 'antd'

// Helpers / Hooks
import { useMutation } from '@apollo/react-hooks'
import { hasPermission } from '../../utilities/permissions'
import { useGraphqlError, useErrors } from '../../utilities/hooks'

const RequestBulkActionsDropdown = ({ selectedRowKeys, clearSelection, requestType }) => {
  const hasAccess = hasPermission('admin_support_requests')
  const [updateSmsCampaignRequest, { data: updateSmsData, loading: updateSmsLoading, error: updateSmsCampaignError }] = useMutation(
    UPDATE_SMS_CAMPAIGN_REQUEST,
    { errorPolicy: 'all' }
  )
  useErrors(updateSmsData?.updateSmsCampaignRequest?.errors)
  useGraphqlError(updateSmsCampaignError)

  const [updateReprintRequest, { data: updateReprintData, loading: updateReprintLoading, error: updateReprintError }] = useMutation(UPDATE_REPRINT_REQUEST, {
    errorPolicy: 'all'
  })
  useErrors(updateReprintData?.updateReprintRequest?.errors)
  useGraphqlError(updateReprintError)

  const loading = updateSmsLoading || updateReprintLoading

  const handleBulkRequest = useCallback(
    async (action) => {
      const params = {
        variables: {
          input: {
            ids: selectedRowKeys,
            status: null
          }
        }
      }

      if (action === 'approve') {
        params.variables.input.status = 'approved'
      }
      if (action === 'approveManual') {
        params.variables.input.status = 'approved_manual'
      }
      if (action === 'complete') {
        params.variables.input.status = 'complete'
        params.variables.input.comment = 'Manual Complete'
      }

      if (requestType === 'smsCampaign') {
        const response = await updateSmsCampaignRequest(params, {})
        const errors = response.data?.updateSmsCampaignRequest?.errors || response?.errors

        if (errors && errors.length > 0) return message.error(errors[0].message)
      } else if (requestType === 'reprint') {
        const response = await updateReprintRequest(params, {})
        const errors = response.data?.updateReprintRequests?.errors || response?.errors
        if (errors && errors.length > 0) return message.error(errors[0].message)
      } else {
        return message.error('Invalid request type.')
      }

      message.success(`${selectedRowKeys.length} support requests updated.`)
      PubSub.publish('PAGED_LIST_REFRESH')
      clearSelection()
    },
    [requestType, selectedRowKeys, clearSelection, updateReprintRequest, updateSmsCampaignRequest]
  )

  const menu = (
    <Menu>
      <Menu.Item key="approve">
        {hasAccess && (
          <div className="link" onClick={() => handleBulkRequest('approve')} style={{ cursor: 'pointer' }}>
            Approve
          </div>
        )}
        {!hasAccess && (
          <Tooltip title="You must request permission to be able to manage support entities.">
            <span style={{ color: 'gray' }}>Approve</span>
          </Tooltip>
        )}
      </Menu.Item>
      {requestType === 'reprint' && (
        <Menu.Item key="approveManual">
          {hasAccess && (
            <div className="link" onClick={() => handleBulkRequest('approveManual')} style={{ cursor: 'pointer' }}>
              Approve - Manual
            </div>
          )}
          {!hasAccess && (
            <Tooltip title="You must request permission to be able to manage support entities.">
              <span style={{ color: 'gray' }}>Approve - Manual</span>
            </Tooltip>
          )}
        </Menu.Item>
      )}
      <Menu.Item key="complete">
        {hasAccess && (
          <div className="link" onClick={() => handleBulkRequest('complete')} style={{ cursor: 'pointer' }}>
            Complete
          </div>
        )}
        {!hasAccess && (
          <Tooltip title="You must request permission to be able to manage support entities.">
            <span style={{ color: 'gray' }}>Complete</span>
          </Tooltip>
        )}
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      {selectedRowKeys && selectedRowKeys.length > 1 && (
        <Dropdown overlay={menu} trigger={['click']} disabled={loading}>
          <span className="ant-dropdown-link" style={{ marginLeft: '14px' }}>
            Bulk Actions &nbsp;
            <DownOutlined />
          </span>
        </Dropdown>
      )}
    </>
  )
}

export default RequestBulkActionsDropdown
