import React from 'react'
import DrawerLink from '../shared/DrawerLink'
import PolicyDrawer from './PolicyDrawer'

const PolicyLink = ({ companyPolicy, clickable = true, children }) => {
  return (
    <DrawerLink drawerElement={PolicyDrawer} childProps={{ id: companyPolicy.id }} clickable={clickable} width={500}>
      {children || companyPolicy.name}
    </DrawerLink>
  )
}

export default PolicyLink
