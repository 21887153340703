import React from 'react'
import Image from '../shared/Image'
import DrawerLink from '../shared/DrawerLink'
import StudioDrawer from './StudioDrawer'

const StudioLink = ({ studio, clickable = true, children }) => {
  if (studio == null) return <>None</>

  return (
    <DrawerLink drawerElement={StudioDrawer} childProps={{ id: studio.id }} clickable={clickable}>
      {children || (
        <>
          <Image isImagizer style={{ paddingRight: 5 }} src={studio.logoUrl} alt={`${studio.name} Logo`} size={{ width: 40, height: 40 }} />
          {studio?.reportingCode ? `${studio?.name} (${studio?.reportingCode})` : studio?.name}
        </>
      )}
    </DrawerLink>
  )
}

export default StudioLink
