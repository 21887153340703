import React from 'react'
import { Form, Input, Modal, Button, message, DatePicker } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import { useGraphqlError, useErrors } from '../../utilities/hooks'
import Drawer from '../shared/Drawer'
import { UPDATE_POLICY } from '../../graphql/mutations'
import moment from 'moment'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}
const tailLayout = {
  wrapperCol: { offset: 6, span: 18 }
}

const PoliciesEditDrawer = (props) => {
  const { companyPolicy, onClose } = props
  const [updateCompanyPolicy, { data, loading, error }] = useMutation(UPDATE_POLICY)
  useErrors(data?.updateCompanyPolicy?.errors)
  useGraphqlError(error)

  const onFinish = async (values) => {
    delete values.attributes.key
    const params = { variables: { input: { id: companyPolicy.id, attributes: values.attributes } } }
    const response = await updateCompanyPolicy(params)
    if (response?.data?.updateCompanyPolicy?.errors?.length === 0) {
      message.success('Company Policy updated.')
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const confirmUpdate = (values) => {
    onClose()
    Modal.confirm({
      title: 'Do you agree with the new policy?',
      icon: <ExclamationCircleOutlined />,
      okText: 'I understand',
      cancelText: 'No',
      onOk: () => onFinish(values)
    })
  }

  const initialValues = {
    attributes: {
      customerChangedAt: moment(companyPolicy.customerChangedAt, 'YYYY-MM-DD'),
      userChangedAt: moment(companyPolicy.userChangedAt, 'YYYY-MM-DD'),
      description: companyPolicy.description,
      name: companyPolicy.name,
      key: companyPolicy.key
    }
  }

  return (
    <Drawer {...props} width={600}>
      <h3>Company Policy Edit: {companyPolicy.id}</h3>
      <Form {...layout} style={{ padding: 20 }} name="basic" initialValues={initialValues} onFinish={confirmUpdate} onFinishFailed={onFinishFailed}>
        <Form.Item label="Name" name={['attributes', 'name']}>
          <Input />
        </Form.Item>
        <Form.Item label="Description" name={['attributes', 'description']}>
          <Input />
        </Form.Item>
        <Form.Item label="Key" name={['attributes', 'key']}>
          <Input disabled />
        </Form.Item>
        <Form.Item label="User Changed" name={['attributes', 'userChangedAt']}>
          <DatePicker />
        </Form.Item>
        <Form.Item label="Customer Changed" name={['attributes', 'customerChangedAt']}>
          <DatePicker />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default PoliciesEditDrawer
