import React from 'react'
import DrawerLink from '../shared/DrawerLink'
import RefundDrawer from './RefundDrawer'

const RefundLink = ({ children, refund, clickable = true }) => {
  return (
    <DrawerLink drawerElement={RefundDrawer} childProps={{ id: refund.id }} clickable={clickable} width={500}>
      {children || refund.id}
    </DrawerLink>
  )
}

export default RefundLink
