import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import NotFound from '../components/pages/NotFound'
import SignIn from '../components/pages/SignIn'
import Dashboard from '../components/pages/Dashboard'
import Jobs from '../components/pages/Jobs'
import Orders from '../components/pages/Orders'
import Order from '../components/pages/Order'
import Refunds from '../components/pages/Refunds'
import Credits from '../components/pages/Credits'
import Studios from '../components/pages/Studios'
import Users from '../components/pages/Users'
import Customers from '../components/pages/Customers'
import Labs from '../components/pages/Labs'
import Requests from '../components/pages/Requests'
import Request from '../components/pages/Request'
import SmsCampaigns from '../components/pages/SmsCampaigns'
import SmsCampaign from '../components/pages/SmsCampaign'
import Policies from '../components/pages/Policies'

const AppRoutes = ({ children }) => {
  return (
    <BrowserRouter>
      <QueryParamProvider>
        {children}
        <Routes>
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/orders/:num" element={<Order />} />
          <Route path="/policies" element={<Policies />} />
          <Route path="/refunds" element={<Refunds />} />
          <Route path="/credits" element={<Credits />} />
          <Route path="/studios" element={<Studios />} />
          <Route path="/users" element={<Users />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/labs" element={<Labs />} />
          <Route path="/reprints" element={<Requests />} />
          <Route path="/reprints/:id" element={<Request />} />
          <Route path="/sms-campaigns" element={<SmsCampaigns />} />
          <Route path="/sms-campaigns/:id" element={<SmsCampaign />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </QueryParamProvider>
    </BrowserRouter>
  )
}

export default AppRoutes
