import React from 'react'
import { Button, Tooltip } from 'antd'
import DrawerLink from '../shared/DrawerLink'
import ZeroCreditsDrawer from './ZeroCreditsDrawer'
import { get } from '../../utilities/storage'

const ZeroButton = ({ tableParams }) => {
  const isSuperAdmin = get('user')?.isSuperAdmin

  return (
    <DrawerLink drawerElement={ZeroCreditsDrawer} clickable={isSuperAdmin} width={500} childProps={{ isSuperAdmin }}>
      <div style={{ float: 'right' }}>
        <Tooltip title={isSuperAdmin ? null : 'You must request access to be able to zero credits'}>
          <Button disabled={!isSuperAdmin} type="primary">
            Zero Credits
          </Button>
        </Tooltip>
      </div>
    </DrawerLink>
  )
}

export default ZeroButton
