import React from 'react'
import Copy from '../shared/Copy'
import { dateFormatter, idFormatter } from '../../utilities/formatters'
import listProps from '../../utilities/listProps'
import Image from '../shared/Image'
import { LABS_LIST_QUERY } from '../../graphql/queries'

const labListProps = listProps('labs', LABS_LIST_QUERY, {
  search: false,
  columns: [
    {
      dataIndex: 'id',
      title: 'ID',
      width: 110,
      render: (id) => <Copy text={id}>{idFormatter(id)}</Copy>
    },
    {
      dataIndex: 'name',
      title: 'Name',
      sorter: true,
      render: (_, lab) => (
        <>
          <Image isImagizer style={{ paddingRight: 20 }} src={lab?.logoUrl} alt={`${lab.name} Logo`} size={{ width: 120, height: 120 }} />
          {lab.name}
        </>
      )
    },
    {
      dataIndex: 'websiteUrl',
      title: 'Website',
      render: (url) => (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      )
    },
    {
      dataIndex: 'contactUrl',
      title: 'Contact',
      render: (url) => (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      )
    },
    {
      dataIndex: 'createdAt',
      title: 'Created',
      sorter: true,
      render: dateFormatter
    },
    {
      dataIndex: 'publishedAt',
      title: 'Published',
      sorter: true,
      render: dateFormatter
    }
  ]
})

export default labListProps
