import React, { useEffect } from 'react'
import { Descriptions, Divider, Button } from 'antd'
import Prismjs from 'prismjs'
import { GoogleOutlined } from '@ant-design/icons'
import Copy from '../../shared/Copy'
import Steps from './Steps'
import { JobLink, StudioLink, CustomerLink } from '../../shared/Links'
import { phoneFormatter, emailFormatter, jsonFormatter, studioLinkFormatter, addressFormatter, linkFormatter, studioHref } from '../../../utilities/formatters'

const { Item } = Descriptions

const Order = ({ order }) => {
  useEffect(() => {
    Prismjs.highlightAll()
  })

  return (
    <>
      <Steps order={order} />
      <Divider />
      <Descriptions title="Order Details" size="small" column={1} bordered>
        <Item label="ID">
          <Copy text={order.id}>{order.id}</Copy>
        </Item>
        <Item label="Studio">
          <StudioLink studio={order.studio} />
        </Item>
        <Item label="Job">
          <JobLink job={order.job} /> &nbsp;
          {studioLinkFormatter(`/jobs/${order.job.id}/dashboard`)}
        </Item>
        <Item label="Customer">
          <CustomerLink customer={order.customer} />
        </Item>
        <Item label="E-mail">{emailFormatter(order.email)}</Item>
        <Item label="Phone">{phoneFormatter(order.phone)}</Item>
        <Item label="Address">
          {addressFormatter(order.address)} &nbsp;
          <a href={`https://maps.google.com/?q=${addressFormatter(order.address, false)}`} target="_blank" rel="noopener noreferrer">
            <GoogleOutlined />
          </a>
        </Item>
        <Item label="Subtotal">{order.subtotal}</Item>
        <Item label="Shipping">{order.shipping}</Item>
        <Item label="Taxes">{order.taxesAmount}</Item>
        <Item label="Total">{order.total}</Item>
        <Item label="Tracking URL">{linkFormatter(order.trackingUrl, 'N/A')}</Item>
        {order.taxDetails && (
          <Item label="Tax Details (Post 2019)">
            <Copy text={order.taxDetails.toString()}>
              <div>{jsonFormatter(order.taxDetails.toString())}</div>
            </Copy>
          </Item>
        )}
        {order.taxInformation && (
          <Item label="Tax Information (Pre 2019)">
            <Copy text={order.taxInformation.toString()}>
              <div>{jsonFormatter(order.taxInformation.toString())}</div>
            </Copy>
          </Item>
        )}
      </Descriptions>
      <Button href={studioHref(`/storefront/orders/${order.id}`)} target="_blank">
        View in studio site
      </Button>
    </>
  )
}

export default Order
