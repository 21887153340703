import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import PubSub from 'pubsub-js'
import Search from './Search'
import Controls from './Controls'
import useListQuery from '../../api/useListQuery'
import { tableMap } from '../../api/helpers'

// Configure default paging with any other starting options applied
const initialParams = (startParams, controls) => {
  let params = { ...{ page: 1, perPage: 10 }, ...startParams }
  if (controls) {
    controls.forEach((control) => {
      if (control.defaultValue) params[control.dataField] = control.defaultValue
    })
  }
  return params
}

const paging = (pageInfo) => {
  if (pageInfo == null) return false
  if (pageInfo.totalCount <= pageInfo.perPage) return false
  return {
    total: pageInfo.totalCount,
    current: pageInfo.page,
    pageSize: pageInfo.perPage
  }
}

const PagedList = (props) => {
  const { search, startParams, query, dataKey, searchPlaceholder, controls, selectedRowKeys, clearSelection } = props
  const [params, setParams] = useState(initialParams(startParams, controls))
  const { items, pageInfo, loading, refetch } = useListQuery(dataKey, query, params)

  const updateParams = (newParams) => {
    setParams({ ...params, ...newParams })
  }

  // Called when any row is sorted etc
  const onTableChange = (pagination, _filters, sorter) => {
    let tableParams = { page: pagination.current, order: sorter.order }
    if (sorter.order) tableParams['order'] = sorter.order
    if (sorter.field) tableParams['field'] = sorter.field
    let serverParams = tableMap(tableParams, 'server')
    serverParams = { ...serverParams, ...{ perPage: pagination.pageSize } }
    updateParams(serverParams)
  }

  useEffect(() => {
    const token = PubSub.subscribe('PAGED_LIST_REFRESH', refetch)
    return () => {
      PubSub.unsubscribe(token)
    }
  }, [refetch])

  return (
    <div>
      {search && (
        <Search
          placeholder={searchPlaceholder}
          onSearch={(term) => {
            updateParams({ search: term, page: 1 })
          }}
        />
      )}
      {controls && (
        <Controls
          options={controls}
          tableParams={params}
          tableData={items}
          onChange={(params) => {
            params.page = 1
            updateParams(params)
          }}
          selectedRowKeys={selectedRowKeys}
          clearSelection={clearSelection}
        />
      )}
      <Table rowKey="id" size="middle" loading={loading} dataSource={items} onChange={onTableChange} pagination={paging(pageInfo)} {...props} />
    </div>
  )
}

export default PagedList
