import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import { DashboardOutlined, BuildOutlined, ShoppingOutlined, ProfileOutlined, CustomerServiceOutlined } from '@ant-design/icons'
import React from 'react'

const { SubMenu } = Menu

const Navigation = ({ section, selectSection }) => {
  const defaultOpenKeys = selectSection && ['store', 'payments', 'tools', 'profiles', 'support']
  const selectedKeys = selectSection && section
  const defaultSelectedKeys = selectSection && ['dashboard']
  return (
    <Menu theme="dark" mode="inline" defaultSelectedKeys={defaultSelectedKeys} selectedKeys={selectedKeys} defaultOpenKeys={defaultOpenKeys}>
      {selectSection && (
        <Menu.Item key="dashboard">
          <DashboardOutlined />
          <span>
            <Link style={{ color: 'white' }} to="/">
              Dashboard
            </Link>
          </span>
        </Menu.Item>
      )}
      {!selectSection && (
        <SubMenu
          key="dashboard"
          title={
            <span>
              <DashboardOutlined />
              <span>Dashboard</span>
            </span>
          }
        >
          <Menu.Item key="dashboard">
            <Link to="/">Home</Link>
          </Menu.Item>
        </SubMenu>
      )}
      <SubMenu
        key="store"
        title={
          <span>
            <BuildOutlined />
            <span>Store</span>
          </span>
        }
      >
        <Menu.Item key="jobs">
          <Link to="/jobs">Jobs</Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="payments"
        title={
          <span>
            <ShoppingOutlined />
            <span>Payments</span>
          </span>
        }
      >
        <Menu.Item key="orders">
          <Link to="/orders">Orders</Link>
        </Menu.Item>
        <Menu.Item key="credits">
          <Link to="/credits">Credits</Link>
        </Menu.Item>
        <Menu.Item key="refunds">
          <Link to="/refunds">Refunds</Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="tools"
        title={
          <span>
            <ShoppingOutlined />
            <span>Tools</span>
          </span>
        }
      >
        <Menu.Item key="Policies">
          <Link to="/policies">Policies</Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="support"
        title={
          <span>
            <CustomerServiceOutlined />
            <span>Support</span>
          </span>
        }
      >
        <Menu.Item key="reprints">
          <Link to="/reprints">Reprints</Link>
        </Menu.Item>
        <Menu.Item key="sms-campaigns">
          <Link to="/sms-campaigns">SMS Campaigns</Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="profiles"
        title={
          <span>
            <ProfileOutlined />
            <span>Profiles</span>
          </span>
        }
      >
        <Menu.Item key="studios">
          <Link to="/studios">Studios</Link>
        </Menu.Item>
        <Menu.Item key="users">
          <Link to="/users">Users</Link>
        </Menu.Item>
        <Menu.Item key="customers">
          <Link to="/customers">Customers</Link>
        </Menu.Item>
        <Menu.Item key="labs">
          <Link to="/labs">Labs</Link>
        </Menu.Item>
      </SubMenu>
    </Menu>
  )
}

export default Navigation
